<style scoped>
	.cntnr {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -100%);
	}
</style>
<template>
	<v-card width="100vw" max-width="360px" outlined class="cntnr">
		<v-card-title>{{ tenantName }}</v-card-title>
		<v-card-subtitle>{{ $t('ResetPassword.Password_forgotten') }}</v-card-subtitle>
		<v-divider />
		<v-card-text class="py-8 py-lg-16">
			<v-window v-model="step">
				<v-window-item :value="1">
					<p class="text-caption">
						{{ $t('ResetPassword.We_will_send_a_link_to') }}
					</p>
					<v-text-field outlined :label="$t('ResetPassword.Email_address')" v-model="mailTo"></v-text-field>
				</v-window-item>
				<v-window-item :value="2">
					<v-card-text>
						<p class="red--text text-center my-4">
							{{ errMsg }}
						</p>
					</v-card-text>
				</v-window-item>
				<v-window-item :value="3">
					<v-card-text>
						<p class="text-center my-4">
							{{ $t('ResetPassword.We_have_sent_a_link') }}
						</p>
					</v-card-text>
				</v-window-item>
			</v-window>
		</v-card-text>
		<v-card-actions>
			<v-btn v-if="step === 2" plain @click.stop="retry"> Terug </v-btn>
			<v-spacer />
			<v-btn v-if="step === 1" :disabled="!mailTo" :loading="loading" plain color="primary"
				@click.stop="resetPassword">
				{{ $t('ResetPassword.Send_link') }}
			</v-btn>

			<v-btn v-if="step === 3" plain @click.stop="backToLogin">
				{{ $t('ResetPassword.Back_to_login') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
import axios from 'axios';
export default {
	name: "ResetPassword",
	data: () => ({
		step: 1,
		mailTo: "",
		error: false,
		errMsg: "",
		loading: false,
		appName: process.env.VUE_APP_APP_NAME,
		tenantName: process.env.VUE_APP_TENANT_NAME,
		tenantId: process.env.VUE_APP_TENANT_ID,
	}),
	activated() {
		//Called when a kept-alive component is activated.
		this.step = 1;
		this.mailTo = this.$router.currentRoute.params.mailTo;
	},
	methods: {
		backToLogin() {
			this.$router.push({ name: "Login" });
		},
		async resetPassword() {
			this.loading = true;
			const apiClient = axios.create({
				baseURL: process.env.VUE_APP_CLOUDFUNCTIONS_ENDPOINT,
				withCredentials: false,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			});
			const data = {
				tenantId: Number(this.tenantId),
				email: this.mailTo,
				url: process.env.VUE_APP_APP_URL,
				language: navigator.language.split('-')[0]
			}
			try {
				await apiClient.post("/authentication-portalUsers-resetPassword", data);
				this.error = false;
				this.errMsg = null;
				this.step = 3;
				this.loading = false;
			} catch (error) {
				this.error = true;
				this.errMsg = error;
				this.step = 2;
				this.loading = false;
			}
		},
		retry() {
			this.step = 1;
		},
	},
};
</script>
